

















































import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { GridAccessPoint } from '@/models'
import { chatService } from '@/services'

@Component
export default class IdentificationConfirmation extends Vue {
  //#region [Props]
  @Prop({ type: String, required: true }) public readonly eanCode!: string
  @Prop({ type: Object, required: false, default: null }) public readonly eanInfo!: GridAccessPoint | null
  //#endregion

  //#region [Data]
  //#endregion

  //#region [Computed]
  private get eanInfoNotFound(): boolean {
    return this.eanInfo == null;
  }

  private get addressFromConsumption() {
    if (this.eanInfo) {
      const address = this.eanInfo.consumptionAddress
      let str = address.streetName
      if (address.houseNumber) {
        str += ' ' + address.houseNumber
      }
      if (address.complement) {
        str += ' ' + address.complement
      }
      if (address.complementBox) {
        str += ' ' + address.complementBox
      }
      if (address.floorNumber) {
        str += ` (${this.$t(`common.identification.floor`).toString()}: ${address.floorNumber})`
      }
      str += ', ' + address.zipCode
      str += ' ' + address.cityName
      return str
    }
    return ''
  }
  //#endregion

  //#region [Method]
  private openChat() {
    chatService.openChat()
  }
  //#endregion
}
