



























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { FileInfo } from '@/models'
import { FileStatus } from '@ores/vue-library'

@Component({
  name: 'attachment-form',
})
export default class AttachmentForm extends Vue {
  //#region [Property]
  @Prop({ type: Array, required: true }) public readonly value!: FileInfo[]
  @Prop({ type: Boolean, required: false, default: false }) public readonly required!: boolean
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'complaint' })
  public readonly requestId!: string
  //#endregion

  //#region [Computed]
  public get fileInError(): boolean {
    return this.value.some((v) => v.error !== null)
  }
  //#endregion

  //#region [Method]
  public async uploadFiles(formData: FormData): Promise<{ data: FileStatus[] }> {
    this.$validatorObs.reset()
    return await this.$api.post<FileStatus[]>(
      '/api/Attachments/complaint?requestId=' + this.requestId,
      formData,
    )
  }

  public async deleteFiles(files: { data: string[] }): Promise<void> {
    return await this.$api.delete('/api/Attachments/complaint?requestId=' + this.requestId, files)
  }
  //#endregion

  //#region [Watch]
  @Watch('value')
  public onValueChanged(value: FileInfo[]) {
    this.$emit('input', value)
  }

  @Watch('required')
  public onRequiredChanged() {
    if (!this.required) {
      this.$validatorObs.reset()
    }
  }
  //#endregion
}
