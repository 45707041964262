
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import EnergySelector from '@/components/inputs/energy/EnergySelector.vue'
import { EnergySelectionStepModel, CategorySelectionStepModel, IdentificationModel, EnergyType } from '@/models'
import { State, Mutation } from 'vuex-class'
import { complaintService } from '@/services'

@Component({
  name: 'energy-info-form',
  components: {
    EnergySelector,
  },
})
export default class EnergyInfoForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: EnergySelectionStepModel
  //#endregion

  //#region [Data]
  @State('identification', { namespace: 'complaint/identification' })
  public identificationState!: IdentificationModel | null
  @State('requestId', { namespace: 'complaint' })
  public requestId!: string
  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'complaint/energy' })
  private store!: (index: EnergySelectionStepModel | null) => void
  //#endregion

  //#region [Computed]
  public get showStreetlightRef(): boolean {
    return complaintService.isInList(this.categorySelectionState, complaintService.streetLightRefOptionalList)
  }

  public get showEanCode(): boolean {
    return this.identificationState?.identificationType !== 'ean'
  }

  public get showCabinNumber(): boolean {
    return complaintService.isInList(this.categorySelectionState, complaintService.cabinNumberOptionalList)
  }

  public get showRechargingTerminal(): boolean {
    return complaintService.isInList(this.categorySelectionState, complaintService.rechargingTerminalOptionalList)
  }

  public get energies(): string[] {
    let energies: string[] = []
    if (complaintService.isInList(this.categorySelectionState, complaintService.energyRequiredList)) {
      if (this.identificationState?.identificationType === 'ean') {
        energies = this.identificationState.eanInfo?.energyType === EnergyType.Electricity ? ['electricity'] : ['gas']
      } else {
        const gridManagers = this.identificationState?.address?.subCity?.gridManagers
        const allowGas = (gridManagers?.gasManager && gridManagers?.gasManager.isOres) ?? false
        const allowElectricity = (gridManagers?.electricityManager && gridManagers?.electricityManager.isOres) ?? false

        if (allowGas) {
          energies.push('gas')
        }
        if (allowElectricity) {
          energies.push('electricity')
        }

        if (energies.length === 0) {
          energies = ['electricity', 'gas']
        }
      }

      if (energies.length === 1) {
        this.value.energySelection = {
          electricity: energies[0] === 'electricity',
          gas: energies[0] === 'gas',
          electricityAndGas: energies[0] === 'electricityAndGas',
          streetlight: energies[0] === 'streetlight',
          notAvailable: energies[0] === 'notAvailable',
        }
      }
    } else {
      this.value.energySelection = null
    }

    return energies
  }
  //#endregion
}
