












































































import Step from '@/components/steps/Step'
import IdentificationByEan from '@/components/inputs/complaint/identification/IdentificationByEan.vue'
import AddressSelector from '@/components/inputs/address/AddressSelector.vue'
import Component from 'vue-class-component'
import { AddressPoint, CategorySelectionStepModel, GridAccessPoint, IdentificationModel } from '@/models'
import { Mutation, State } from 'vuex-class'
import { complaintService, helper } from '@/services'
import { Emit, Prop } from 'vue-property-decorator'

@Component({
  name: 'identification-step',
  components: {
    IdentificationByEan,
    AddressSelector,
  },
})
export default class IdentificationStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: false }) public eanRequired!: boolean
  //#endregion

  //#region [Data]
  @State('identification', { namespace: 'complaint/identification' })
  public identificationState!: IdentificationModel | null
  @State('requestId', { namespace: 'complaint' })
  public requestId!: string
  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null

  public identificationType: string | null = null
  public address: AddressPoint | null = null
  public eanCode: string | null = null
  public eanInformation: GridAccessPoint | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'complaint/identification' })
  private store!: (index: IdentificationModel | null) => void
  //#endregion

  //#region [Computed]
  public get isNotScheduledElectricityCutoff(): boolean {
    return (
      !!this.categorySelectionState &&
      !!this.categorySelectionState.category &&
      this.categorySelectionState.category.key === 'notScheduledElectricityCutoff'
    )
  }

  public get oresAddressOnly(): boolean {
    return complaintService.isInList(this.categorySelectionState, complaintService.oresAddresOnlyList)
  }

  public get identificationTitle(): string {
    return this.eanRequired
      ? `${this.$t('complaint.identification.title')} ${this.$t('complaint.identification.byEan')}`
      : this.$t('complaint.identification.title').toString()
  }

  public get numberRequired(): boolean {
    return !complaintService.isInList(this.categorySelectionState, complaintService.numberNotRequiredList)
  }

  public get streetRequired(): boolean {
    return !complaintService.isInList(this.categorySelectionState, complaintService.streetNotRequiredList)
  }

  public get addressLabel(): string {
    if (complaintService.isInList(this.categorySelectionState, complaintService.consumptionAddressLabelList)) {
      return this.$t('complaint.identification.byConsumptionAddress').toString()
    }
    if (complaintService.isInList(this.categorySelectionState, complaintService.complaintAddressLabelList)) {
      return this.$t('complaint.identification.byComplaintAddress').toString()
    }
    if (complaintService.isInList(this.categorySelectionState, complaintService.addressLabelList)) {
      return this.$t('complaint.identification.byAddress').toString()
    }
    return ''
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.isNotScheduledElectricityCutoff) {
      this.emitCanContinue(false)
    }

    if (this.identificationState) {
      this.identificationType = helper.clone(this.identificationState.identificationType)
      this.address = helper.clone(this.identificationState.address)
      this.eanCode = helper.clone(this.identificationState.eanCode)
      this.eanInformation = helper.clone(this.identificationState.eanInfo)
    }

    if (this.eanRequired) {
      this.identificationType = 'ean'
      this.address = null
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid && this.identificationType) {
      this.$appInsights.trackEvent('Complaint_StepIdentification_Validated', {
        Segment: 'Complaint',
        WebRef: this.requestId,
        IdentificationType: this.identificationType,
      })
    }
    this.store(
      this.identificationType === 'ean'
        ? {
            identificationType: this.identificationType,
            address: null,
            eanCode: this.eanCode,
            eanInfo: this.eanInformation,
          }
        : {
            identificationType: this.identificationType,
            address: this.address,
            eanCode: null,
            eanInfo: null,
          },
    )
  }

  @Emit('canContinue')
  public emitCanContinue(value: boolean) {
    return value
  }
  //#endregion
}
