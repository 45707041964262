























































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { Category, CategorySelectionStepModel } from '@/models';

@Component
export default class CategorySelector extends Vue {
  //#region [Property]
  @Prop({ required: true, type: Array }) public categories!: Category[];
  @Prop({ required: false, type: Object }) public value!: CategorySelectionStepModel | null;
  //#endregion

  //#region [Data]
  public networkProblemsModalOpen: boolean = false
  //#endregion

  //#region [Computed]
  public get parentCategory(): string | null {
      return this.value && this.value.parentCategory ? this.value.parentCategory.key : null;
  }

  public set parentCategory(val: string | null) {
    const cat = this.categories.find((c) => c.key === val) || null;
    this.change(cat, null);
    this.$validatorObs.reset();
  }

  public get category(): string | null {
      return this.value && this.value.category ? this.value.category.key : null;
  }

  public set category(val: string | null) {
    const cat = this.value!.parentCategory!.subCategories!.find((c) => c.key === val) || null;
    this.change(this.value!.parentCategory, cat);
  }

  //#endregion

  //#region [Emit]
    @Emit('input')
    public change(
      parentCategory: Category | null,
      category: Category | null
    ): CategorySelectionStepModel | null {
      return { parentCategory, category };
    }
  //#endregion

  //#region [Watch]
  @Watch('parentCategory')
  public onParentCategoryChanged(newVal: string | null) {
    this.networkProblemsModalOpen = newVal === 'networkProblems'
  }
  //#endregion
}
