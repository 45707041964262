













import { Component, Watch } from 'vue-property-decorator';
import Step from '@/components/steps/Step';
import ContactInfoForm from '@/components/inputs/contact/ContactInfoForm.vue';
import { State, Mutation } from 'vuex-class';
import { ContactInfo, IdentificationModel } from '@/models';
import { helper, contactService } from '@/services';

@Component({
  name: 'contact-info-step',
  components: {
    ContactInfoForm,
  },
})
export default class ContactStep extends Step {
  //#region [Data]
  @State('identification', { namespace: 'complaint/identification' })
  public identificationState!: IdentificationModel | null;
  @State('requestId', { namespace: 'complaint' })
  public requestId!: string;
  @State('contactInfo', { namespace: 'complaint/contactInfo' })
  public contactInfoState!: ContactInfo | null;
  public contactInfo: ContactInfo = contactService.createContactInfo();
  public contactAddressRequired: boolean = false;
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'complaint/contactInfo' })
  private store!: (index: ContactInfo | null) => void;
  //#endregion

  //#region [Computed]
  public get consumptionAddress() {
    return this.identificationState?.eanInfo?.consumptionAddress;
  }
  //#endregion

  //#region [Watch]
  @Watch('identificationState')
  public onIdentificationStateChanged(value: IdentificationModel | null) {
    if (value && value.identificationType === 'address' &&
       (value.address?.streetSubCity === null || value.address?.houseNumber === null)) {
      this.contactInfo.useOtherAddress = true;
      this.contactAddressRequired = true;
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.contactInfoState !== null) {
      this.contactInfo = helper.clone(this.contactInfoState);
    }
    this.onIdentificationStateChanged(this.identificationState);
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid && this.contactInfo !== null) {
      this.$appInsights.trackEvent('Complaint_StepContact_Validated', {
        Segment: 'Complaint',
        WebRef: this.requestId,
      });
    }

    this.store(this.contactInfo);
  }
  //#endregion
}
