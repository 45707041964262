


































import { Component } from 'vue-property-decorator';
import CategorySelector from '@/components/inputs/complaint/category/CategorySelector.vue';
import { Category, CategorySelectionStepModel } from '@/models';
import Step from '../Step';
import { Mutation, State } from 'vuex-class';
import { helper } from '@/services';

@Component({
  components: {
    CategorySelector,
  },
})
export default class CategorySelectorStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'complaint' })
  public readonly requestId!: string;
  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null;
  public categories: Category[] = [];
  public category: CategorySelectionStepModel | null = null;
  public workComplaintSubject: string | null = null;
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'complaint/categorySelection' })
  private store!: (index: CategorySelectionStepModel | null) => void;
  //#endregion

  //#region [Computed]
  public get showCmhBlock(): boolean {
    return this.category?.category?.key === 'cmhService';
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.category = helper.clone(this.categorySelectionState);

    this.categories.push(
      { key: 'myInstallation',
        subCategories: [
          { key: 'indexCompsumption', subCategories: null },
          { key: 'myWorkFile', subCategories: null },
          { key: 'myConnectionMeter', subCategories: null },
          { key: 'cmhService', subCategories: null },
          { key: 'myBudgetMeter', subCategories: null },
          { key: 'cuttOffByProvider', subCategories: null },
        ]
      },
      { key: 'oresLetter',
        subCategories: [
          { key: 'meterReadingRequest', subCategories: null },
          { key: 'worksCutOffMeterReplacement', subCategories: null },
          { key: 'notScheduledSupplyCutoff', subCategories: null },
          { key: 'invoice', subCategories: null },
          { key: 'prime', subCategories: null },
          { key: 'distributionTariff', subCategories: null },
          { key: 'viabilisationFee', subCategories: null },
        ]
      },
      { key: 'oresWorkInitiative',
        subCategories: [
          { key: 'publicHighwayWorks', subCategories: null },
          { key: 'pavementRenovation', subCategories: null },
          { key: 'electricCabinMaintenance', subCategories: null },
        ]
      },
      { key: 'networkProblems', subCategories: null },
      { key: 'oresEmployeeInteraction', subCategories: null },
      { key: 'failure',
        subCategories: [
          { key: 'electricity', subCategories: null },
          { key: 'gaz', subCategories: null },
          { key: 'streetlight', subCategories: null },
          { key: 'rechargingTerminal', subCategories: null },
        ]
      },
      { key: 'rgpdDataManagement', subCategories: null },
      { key: 'indemnityRequest', subCategories: [
          { key: 'notScheduledElectricityCutoff', subCategories: null },
          { key: 'irregularElecticityCutoff', subCategories: null },
          { key: 'damageDueToElectricityIncident', subCategories: null },
          { key: 'connectionWorkDelay', subCategories: null },
          { key: 'electricityGazSupplierSwitchDelay', subCategories: null },
          { key: 'electricityGazInvoiceError', subCategories: null },
          { key: 'solarRequestProcessingDelay', subCategories: null },
        ]
      })
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid && this.category) {
      this.$appInsights.trackEvent('Complaint_StepCategory_Validated', {
        Segment: 'Complaint',
        WebRef: this.requestId,
      });
    }
    this.store(this.category);
  }
  //#endregion
}
