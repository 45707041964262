


















































import { Form } from '@/views/forms/Form';
import { Component, Watch } from 'vue-property-decorator';
import ApiErrors from '@/components/display/ApiErrors.vue';
import StepActions from '@/components/shared/StepActions.vue';
import NewOrTrackingStep from '@/components/steps/complaint/NewOrTrackingStep.vue';
import CategorySelectorStep from '@/components/steps/complaint/CategorySelectorStep.vue';
import IdentificationStep from '@/components/steps/complaint/IdentificationStep.vue';
import ContactStep from '@/components/steps/complaint/ContactStep.vue';
import ComplaintInfoStep from '@/components/steps/complaint/ComplaintInfoStep.vue';
import SummaryStep from '@/components/steps/complaint/SummaryStep.vue';
import SendConfirmation from '@/components/display/SendConfirmation.vue';
import {
  StepDefinition,
  ContactInfo,
  ComplaintInfo,
  CategorySelectionStepModel,
  IdentificationModel,
} from '@/models';
import Step from '@/components/steps/Step';
import { Action, State } from 'vuex-class';
import { localize } from 'vee-validate';
import { complaintService } from '@/services';
import moment from 'moment';

@Component({
  name: 'complaint',
  components: {
    StepActions,
    NewOrTrackingStep,
    CategorySelectorStep,
    IdentificationStep,
    ContactStep,
    ComplaintInfoStep,
    SummaryStep,
    SendConfirmation,
    ApiErrors,
  },
})
export default class Complaint extends Form {
  //#region [Action]
  @Action('validate', { namespace: 'complaint/identification' })
  public validateIdentification!: (category: CategorySelectionStepModel) => Promise<boolean>

  @Action('validate', { namespace: 'complaint/energy' })
  public validateEnergy!: (category: CategorySelectionStepModel) => Promise<boolean>

  @Action('validate', { namespace: 'complaint/complaintInfo' })
  public validateComplaintInfo!: (category: CategorySelectionStepModel) => Promise<boolean>

  @Action('validate', { namespace: 'complaint/contactInfo' })
  public validateContactInfo!: (identification: IdentificationModel) => Promise<boolean>
  //#endregion

  //#region [Data]
  @State('contactInfo', { namespace: 'complaint/contactInfo' })
  public contactInfoState!: ContactInfo | null;

  @State('identification', { namespace: 'complaint/identification' })
  public identificationState!: IdentificationModel | null;

  @State('complaintInfo', { namespace: 'complaint/complaintInfo' })
  public complaintInfoState!: ComplaintInfo | null;

  @State('newOrTracking', { namespace: 'complaint/newOrTracking' })
  public newOrTrackingState!: string | null;

  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null;

  public newOrTracking: 'new' | 'tracking' | '' = '';
  public showStepAction: boolean = true;
  public newStepDefinitions: StepDefinition[] = [
    new StepDefinition('category', 1),
    new StepDefinition('identification', 2),
    new StepDefinition('complaintInfo', 3),
    new StepDefinition('contact', 4),
    new StepDefinition('resume', 5),
    new StepDefinition('confirm', 6),
  ] as StepDefinition[];

  public trackingStepDefinitions: StepDefinition[] = [
    new StepDefinition('complaintInfo', 1),
    new StepDefinition('confirm', 2),
  ] as StepDefinition[];

  public stepDefinitions: StepDefinition[] = this.newStepDefinitions;

  public identificationByEan: boolean = true;
  public eanRequired: boolean = true;
  //#endregion

  //#region [Computed]
  public get sendConfirmationEmail() {
    return this.newOrTrackingState === 'new' ?
      this.contactInfoState?.addOtherContact ?
      this.contactInfoState?.otherContact?.email :
      this.contactInfoState?.contactBase?.email :
      this.complaintInfoState?.email;
  }

  public get nextLabel() {
    return (this.newOrTracking === 'tracking' && this.currentStep === 1) ||
      (this.newOrTracking === 'new' && this.currentStep === 5)
      ? this.$t('common.send').toString()
      : null
  }

    public get complaintTitle() {
    return this.newOrTrackingState === 'new' ?
      this.$t('complaint.complaint') :
      this.$t('complaint.tracking')
  }
  //#endregion

  //#region [Method]
  public beforeCreate() {
    document.title = this.$t('complaint.formTitle').toString()
  }

  public resetForm() {
    this.currentStep = 0;
    this.lastStepSeen = 0;
    this.$store.dispatch('complaint/reset');
  }

  public getStepComponent(step: number): Step | null {
    switch (this.currentStep) {
      case 0:
        return this.$refs.stepNewOrTracking as Step;
      case 1:
        return this.$refs.step1 as Step;
      case 2:
        return this.$refs.step2 as Step;
      case 3:
        return this.$refs.step3 as Step;
      case 4:
        return this.$refs.step4 as Step;
      case 5:
        return this.$refs.step5 as Step;
      default:
        return null;
    }
  }

  public disableNextButton(value: boolean) {
    this.showStepAction = !value;
  }

  public mounted() {
    sessionStorage.clear();
    this.onLangChanged(this.lang);
    this.currentStep = 0;
    this.lastStepSeen = 0;
    this.stepToGoAfterValidation = 5;
    this.stepsToValidate = [1, 2]
  }

  public created() {
    this.CheckMaintenance('complaint');
  }

  //#endregion

  //#region [Watch]
  @Watch('categorySelectionState', { deep: true })
  public async onCategorySelectionStateChanged(category: CategorySelectionStepModel | null) {
    if (category === null) {
      this.eanRequired = false
      return
    }

    this.eanRequired = complaintService.isInList(category, complaintService.eanRequiredList)

    const identificationStepValid = await this.validateIdentification(category)
    const complaintInfoStepValid = await this.validateEnergy(category) && await this.validateComplaintInfo(category)
    if (!identificationStepValid) {
      this.ensureLastStep(2, identificationStepValid)
    } else if (!complaintInfoStepValid) {
      this.ensureLastStep(3, complaintInfoStepValid)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100);
  }

  @Watch('identificationState', { deep: true })
  public async onIdentificationStateChange(identification: IdentificationModel | null) {
    if (identification === null || this.categorySelectionState === null) {
      return
    }

    const identificationStepValid = await this.validateIdentification(this.categorySelectionState)
    const contactStepValid = await this.validateContactInfo(identification)

    if (!identificationStepValid) {
      this.ensureLastStep(2, identificationStepValid)
    } else if (!contactStepValid) {
      this.ensureLastStep(4, contactStepValid)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100);
  }

  @Watch('lang')
  public onLangChanged(value: string) {
    this.$i18n.locale = value;
    localize(value);
    this.$store.commit('SET_LANGUAGE', value);
    moment.locale(value);
  }

  @Watch('newOrTracking')
  public onNewOrTrackingChanged(value: 'new' | 'tracking' | '') {
    switch (value) {
      case 'new':
        this.stepDefinitions = this.newStepDefinitions;
        break;

      case 'tracking':
        this.stepDefinitions = this.trackingStepDefinitions;
        break;

      default:
        break;
    }
  }
  //#endregion
}
