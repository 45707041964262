
































































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import ResumeAddress from '@/components/display/ResumeAddress.vue'
import {
  FileInfo,
  ContactInfo,
  CategorySelectionStepModel,
  ComplaintInfo,
  EnergySelectionStepModel,
  ApiErrorNotification,
  IdentificationModel,
} from '@/models'
import { State } from 'vuex-class'
import { complaintService, dateService, helper } from '@/services'

@Component({
  name: 'summary-step',
  components: {
    ResumeAddress,
  },
})
export default class SummaryStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  //#region [Data]
  @State('apiErrors', { namespace: 'apiErrors' })
  public readonly apiErrors!: ApiErrorNotification | null

  @State('requestId', { namespace: 'complaint' })
  public requestId!: string

  @State('lang')
  public lang!: string | null

  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null

  @State('identification', { namespace: 'complaint/identification' })
  public identificationState!: IdentificationModel | null

  @State('energySelectionStepInfo', { namespace: 'complaint/energy' })
  public energySelectionInfoState!: EnergySelectionStepModel | null

  @State('complaintInfo', { namespace: 'complaint/complaintInfo' })
  public complaintInfoState!: ComplaintInfo | null

  @State('contactInfo', { namespace: 'complaint/contactInfo' })
  public contactInfoState!: ContactInfo | null

  @State('attachments', { namespace: 'complaint/attachments' })
  public attachmentsState!: FileInfo[]

  public recaptcha: string | null = null
  //#endregion

  //#region [Computed]
  public get energyRequired(): boolean {
    return complaintService.isInList(this.categorySelectionState, complaintService.energyRequiredList)
  }

  public get jwtToken(): string | null {
    const token = sessionStorage.getItem('token')
    return token
  }
  public get hasAttachedDocuments(): boolean {
    return this.attachmentsState !== null && this.attachmentsState.length > 0
  }

  private get address() {
    let str = ''
    if (this.identificationState && this.identificationState.identificationType === 'address') {
      const addressPoint = this.identificationState.address!
      if (addressPoint.streetSubCity) {
        if (addressPoint.houseNumber) {
          str += addressPoint.houseNumber
        }
        if (addressPoint.houseBox) {
          str += ' / ' + addressPoint.houseBox
        }
        str += ' ' + addressPoint.streetSubCity.name + ', '
      }
      str += addressPoint.subCity.zipCode
      str += ' ' + addressPoint.subCity.cityName
      return str
    }
    if (this.identificationState && this.identificationState.identificationType === 'ean') {
      const consumptionAddress = this.identificationState.eanInfo?.consumptionAddress!
      str += consumptionAddress.houseNumber
      str += ' ' + consumptionAddress.streetName
      if (consumptionAddress.complement) {
        str += ' ' + consumptionAddress.complement
      }
      if (consumptionAddress.complementBox) {
        str += ' ' + consumptionAddress.complementBox
      }
      if (consumptionAddress.floorNumber) {
        str += ` (${this.$t(`common.identification.floor`).toString()}: ${consumptionAddress.floorNumber})`
      }
      str += ', ' + consumptionAddress.zipCode
      str += ' ' + consumptionAddress.cityName
      return str
    }
    return ''
  }

  public get contactName(): string {
    if (this.contactInfoState && this.contactInfoState.contactBase) {
      let civility = null
      if (this.contactInfoState.contactBase.civility === 1) {
        civility = this.$t('contact.civility.mr')
      } else if (this.contactInfoState.contactBase.civility === 2) {
        civility = this.$t('contact.civility.mrs')
      }
      return `${civility} ${this.contactInfoState.contactBase.firstName} ${this.contactInfoState.contactBase.lastName}`
    }
    return ''
  }

  public get otherContactName(): string {
    if (this.contactInfoState && this.contactInfoState.otherContact) {
      let civility = null
      if (this.contactInfoState.otherContact.civility === 1) {
        civility = this.$t('contact.civility.mr')
      } else if (this.contactInfoState.otherContact.civility === 2) {
        civility = this.$t('contact.civility.mrs')
      }
      return `${civility} ${this.contactInfoState.otherContact.firstName} ${this.contactInfoState.otherContact.lastName}`
    }
    return ''
  }

  public get contactMail(): string {
    if (this.contactInfoState && this.contactInfoState.contactBase) {
      return this.contactInfoState.contactBase.email!
    }
    return ''
  }

  get contactVat(): string | undefined | null {
    return this.contactInfoState?.contactBase?.vatNumber?.trim()
  }

  get companyInfo(): string | undefined | null {
    const contact = this.contactInfoState?.contactBase
    if (contact?.contactType === 'publicAuthority') {
      return `${this.$t('common.contact.publicAuthorityType.' + contact?.companyType)} ${contact?.companyName ?? ''}`
    } else if (contact?.contactType === 'freelanceOrCompany') {
      return `${this.$t('common.contact.companyType.' + contact?.companyType)} ${contact?.companyName ?? ''}`
    }
  }

  public get sinisterDate(): string {
    return this.complaintInfoState?.sinisterDate
      ? dateService.displayDateText(this.complaintInfoState!.sinisterDate)
      : ''
  }

  public get description(): string | null {
    return !this.complaintInfoState ? null : this.complaintInfoState.description
  }

  public sinisterDateValue(): Date | null {
    return complaintService.isInList(this.categorySelectionState, complaintService.indemnityRequestList) ||
      complaintService.isInList(this.categorySelectionState, complaintService.indemnitySendToLegal)
      ? this.complaintInfoState!.sinisterDate
      : null
  }
  //#endregion

  //#region [Method]
  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  public async sendNewComplaint() {
    const files = []
    if (this.attachmentsState && this.attachmentsState.length > 0) {
      for (const file of this.attachmentsState) {
        files.push(file.name)
      }
    }

    let energy = null
    if (this.energyRequired) {
      if (this.energySelectionInfoState!.energySelection?.electricity) {
        energy = 1
      } else if (this.energySelectionInfoState!.energySelection?.gas) {
        energy = 2
      }
    }

    let otherAddress = null
    if (this.contactInfoState!.useOtherAddress) {
      otherAddress = {
        street: this.contactInfoState!.otherAddress!.streetSubCity?.name,
        houseNumber: this.contactInfoState!.otherAddress!.houseNumber,
        houseBox: this.contactInfoState!.otherAddress!.houseBox,
        zipCode: this.contactInfoState!.otherAddress!.subCity?.zipCode,
        subCity: this.contactInfoState!.otherAddress!.subCity?.cityName,
        country: this.contactInfoState!.otherAddress!.country,
      }
    }

    let otherContactPerson: any = null
    if (this.contactInfoState!.addOtherContact && this.contactInfoState!.otherContact) {
      otherContactPerson = helper.clone(this.contactInfoState!.otherContact)
      otherContactPerson.address = {
        street: this.contactInfoState!.otherContact.address.streetSubCity?.name,
        houseNumber: this.contactInfoState!.otherContact.address.houseNumber,
        houseBox: this.contactInfoState!.otherContact.address.houseBox,
        zipCode: this.contactInfoState!.otherContact.address.subCity?.zipCode,
        subCity: this.contactInfoState!.otherContact.address.subCity?.cityName,
        country: this.contactInfoState!.otherContact.address.country,
      }
    }

    const data = {
      language: this.lang,
      webReference: this.requestId,
      complaintParentCategory: this.categorySelectionState!.parentCategory!.key,
      complaintParentCategoryLabel: this.$t('complaint.category.' + this.categorySelectionState!.parentCategory!.key),
      complaintCategory: this.categorySelectionState!.category ? this.categorySelectionState!.category.key : null,
      complaintCategoryLabel: this.categorySelectionState!.category
        ? this.$t('complaint.category.' + this.categorySelectionState!.category!.key)
        : null,
      complaintAddress:
        this.identificationState?.identificationType === 'address' && this.identificationState.address
          ? {
              zipCode: this.identificationState.address.subCity.zipCode,
              subCity: this.identificationState.address.subCity.cityName,
              streetSubCity: this.identificationState.address.streetSubCity?.name,
              houseNumber: this.identificationState.address.houseNumber,
              houseBox: this.identificationState.address.houseBox,
              isMapLocation: this.identificationState.address.isMapLocation,
              mapLocation: this.identificationState.address.mapLocation,
            }
          : null,
      consumptionAddress:
        this.identificationState?.identificationType === 'ean' && this.identificationState.eanInfo
          ? this.identificationState.eanInfo.consumptionAddress
          : null,
      eanNumber:
        this.identificationState?.identificationType === 'ean'
          ? this.identificationState.eanCode?.trim()
          : this.energySelectionInfoState?.eanCode,
      energy,
      streetLightRef: complaintService.isInList(
        this.categorySelectionState,
        complaintService.streetLightRefOptionalList,
      )
        ? this.energySelectionInfoState!.streetlightNumber
        : null,
      cabinNumber: complaintService.isInList(this.categorySelectionState, complaintService.cabinNumberOptionalList)
        ? this.energySelectionInfoState!.cabinNumber
        : null,
      rechargingTerminal: complaintService.isInList(
        this.categorySelectionState,
        complaintService.rechargingTerminalOptionalList,
      )
        ? this.energySelectionInfoState!.rechargingTerminal
        : null,
      bankAccountBic: complaintService.isInList(this.categorySelectionState, complaintService.indemnityRequestList)
        ? this.complaintInfoState!.bankAccountBic
        : null,
      bankAccountIban: complaintService.isInList(this.categorySelectionState, complaintService.indemnityRequestList)
        ? this.complaintInfoState!.bankAccountIban
        : null,
      damageType:
      complaintService.isInList(this.categorySelectionState, complaintService.damageTypeRequiredList)
        ? this.complaintInfoState!.damageType
        : null,
      sinisterDate: this.sinisterDateValue(),
      complaintDescription: this.description,
      isIndemnitySendToLegal:
      complaintService.isInList(this.categorySelectionState, complaintService.indemnitySendToLegal)
        ? this.complaintInfoState?.isIndemnitySendToLegal
        : false,
      attachment: files,
      requestorInfos: this.contactInfoState!.contactBase,
      addOtherAddress: this.contactInfoState!.useOtherAddress,
      otherAddress,
      addOtherContactPerson: this.contactInfoState!.addOtherContact,
      otherContactPerson,
    }

    if (this.jwtToken === null) {
      const loginResponse = await this.$api.get('api/login/token', {
        headers: { 'g-recaptcha-response': this.recaptcha! },
      })
      sessionStorage.setItem('token', loginResponse.data)
    }

    const response = await this.$api.post('api/complaintform', data, {
      headers: { Authorization: sessionStorage.getItem('token') },
    })
    if (response.status >= 200 && response.status < 300) {
      this.$appInsights.trackEvent('Complaint_StepSummary_Validated', {
        Segment: 'Complaint',
        WebRef: this.requestId,
        NewOrTracking: 'new',
        ParentCategoryCategory: this.categorySelectionState!.parentCategory!.key,
        Category: this.categorySelectionState!.category
          ? this.categorySelectionState!.category!.key
          : this.categorySelectionState!.parentCategory!.key,
      })

      return true
    }
  }

  public async save(next: true, valid: boolean) {
    if (!next || !valid) {
      return true
    }

    return await this.sendNewComplaint()
  }
  //#endregion
}
