











import { Vue, Component, Prop } from 'vue-property-decorator';
import { AddressPoint } from '@/models';

@Component
export default class ResumeInfoItem extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly address!: AddressPoint;
  //#endregion
}
