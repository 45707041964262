





















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CategorySelectionStepModel, ComplaintInfo, EnergySelectionStepModel } from '@/models';
import { Getter, State } from 'vuex-class';
import { complaintService, dateService } from '@/services';

@Component
export default class ComplaintInfoForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: ComplaintInfo;
  @Prop({ type: Boolean, required: true }) public readonly isElectricity!: boolean;
  //#endregion

  //#region [Data]
  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null;
  @State('newOrTracking', { namespace: 'complaint/newOrTracking' })
  public readonly newOrTrackingState!: string;
  @State('energySelectionStepInfo', { namespace: 'complaint/energy' })
  public energySelectionInfoState!: EnergySelectionStepModel | null
  @Getter('isIndemnityRequest', { namespace: 'complaint/categorySelection' })
  public readonly isIndemnityRequest!: boolean;
  @Getter('isIndemnitySendToLegal', { namespace: 'complaint/categorySelection' })
  public readonly isIndemnitySendToLegal!: boolean;
  //#endregion

  //#region [Computed]
  public get maxDate(): string {
    return dateService.stringifyDate(new Date())
  }
  public get isTracking() {
    return this.newOrTrackingState === 'tracking';
  }

  public get damageTypeVisible(): boolean {
    return complaintService.isInList(this.categorySelectionState, complaintService.damageTypeRequiredList)
  }

  public get bicVisible(): boolean {
    return (
      this.value.bankAccountIban !== null &&
      this.value.bankAccountIban.length > 1 &&
      !this.value.bankAccountIban.toLowerCase().startsWith('be')
    )
  }

  public get isSinisterDateVisible() {
    const isIndemnitySendToLegalCondition =
      complaintService.isInList(this.categorySelectionState, complaintService.indemnitySendToLegal) &&
      this.value.isIndemnitySendToLegal

    const isSinisterDateMustBeDeleted =
      !isIndemnitySendToLegalCondition ||
      !complaintService.isInList(this.categorySelectionState, complaintService.indemnityRequestList)

    if (isIndemnitySendToLegalCondition) {
      return true
    }

    if (isSinisterDateMustBeDeleted) {
      this.value.sinisterDate = null
    }

    return false
  }
  //#endregion

  //#region [Watch]
  @Watch('value', {deep: true})
  public onValueChanged(newVal: ComplaintInfo) {
    this.initValidation();
  }

  @Watch('value.isIndemnitySendToLegal')
  public isIndemnitySendToLegalChanged() {
    if (!this.value.isIndemnitySendToLegal) {
      this.value.sinisterDate = null
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.initValidation();
  }

  public initValidation() {
    if (this.$validatorObs) {
      const flags = this.$validatorObs.flags;
      if (!this.$validatorObs.flags.validated && this.value.description !== null && this.value.description !== '') {
        this.$nextTick(() => {
          this.$validatorObs.validate();
        });
      }
    }
  }
  //#endregion
}
