
















import { Component } from 'vue-property-decorator';
import ComplaintInfoForm from '@/components/inputs/complaint/complaint-info/ComplaintInfoForm.vue';
import EnergyInfoForm from '@/components/inputs/complaint/complaint-info/EnergyInfoForm.vue';
import AttachmentForm from '@/components/inputs/complaint/complaint-info/AttachmentForm.vue';
import { State, Mutation } from 'vuex-class';
import { helper } from '@/services';
import { ComplaintInfo, EnergySelectionStepModel, FileInfo } from '@/models';
import Step from '../Step';

@Component({
  components: {
    ComplaintInfoForm,
    EnergyInfoForm,
    AttachmentForm
  },
})
export default class ComplaintInfoStep extends Step {
  //#region [Data]
  @State('lang')
  public lang!: string | null;
  @State('requestId', { namespace: 'complaint' })
  public requestId!: string;
  @State('complaintInfo', { namespace: 'complaint/complaintInfo' })
  public complaintInfoState!: ComplaintInfo | null;
  @State('energySelectionStepInfo', { namespace: 'complaint/energy' })
  public energySelectionInfoState!: EnergySelectionStepModel | null;
  @State('attachments', { namespace: 'complaint/attachments' })
  public attachmentsState!: FileInfo[];
  @State('newOrTracking', { namespace: 'complaint/newOrTracking' })
  public readonly newOrTrackingState!: string;

  public attachments: FileInfo[] = [];
  public recaptcha: string | null = null;

  public energySelectionInfo: EnergySelectionStepModel = {
    energySelection: null,
    eanCode: null,
    streetlightNumber: null,
    cabinNumber: null,
    rechargingTerminal: null,
  };

  public complaintInfo: ComplaintInfo = {
    fileNumber: null,
    description: null,
    bankAccountBic: null,
    bankAccountIban: null,
    damageType: null,
    sinisterDate: null,
    email: null,
    isIndemnitySendToLegal: false,
  };
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'complaint/complaintInfo' })
  private storeComplaint!: (index: ComplaintInfo | null) => void;
  @Mutation('STORE', { namespace: 'complaint/energy' })
  private storeEnergy!: (index: EnergySelectionStepModel | null) => void;
  @Mutation('STORE_ATTACHMENTS', { namespace: 'complaint/attachments' })
  private storeAttachments!: (index: FileInfo[]) => void;
  //#endregion

  //#region [Computed]
  public get isTracking() {
    return this.newOrTrackingState === 'tracking';
  }

  public get isElectricity() {
    return this.energySelectionInfo.energySelection?.electricity === true;
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.complaintInfoState !== null) {
      this.complaintInfo = helper.clone(this.complaintInfoState);
    }
    if (this.energySelectionInfoState !== null) {
      this.energySelectionInfo = helper.clone(this.energySelectionInfoState);
    }
    if (this.attachmentsState !== null) {
      this.attachments = helper.clone(this.attachmentsState);
    }
  }

  public async save(next: boolean, isValid: boolean) {
    this.storeComplaint(this.complaintInfo);
    this.storeEnergy(this.energySelectionInfo);
    this.storeAttachments(this.attachments.filter((a) => a.error === null));

    if (this.isTracking) {
      if (!next || !isValid) {
        return true;
      }
      return await this.sendTrackingComplaint();
    } else {
      if (isValid && this.complaintInfo !== null) {
        this.$appInsights.trackEvent('Complaint_StepComplaintInfo_Validated', {
          Segment: 'Complaint',
          WebRef: this.requestId,
        });
      }
    }
  }

  public async sendTrackingComplaint(): Promise<boolean> {
    const files = [];
    const attachments = this.attachments.filter((a) => a.error === null)
    if (attachments && attachments.length > 0) {
      for (const file of attachments) {
        files.push(file.name);
      }
    }
    if (this.complaintInfo) {
      const data = {
        language: this.lang,
        webReference: this.requestId,
        email: this.complaintInfo.email,
        fileNumber: this.complaintInfo.fileNumber,
        complaintDescription: this.complaintInfo.description,
        attachments: files,
      };

      const response = await this.$api.post('api/complaintform/tracking', data, {
        headers: {
          'g-recaptcha-response': this.recaptcha!,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        this.$appInsights.trackEvent('Complaint_StepSummary_Validated', {
          Segment: 'Complaint',
          WebRef: this.requestId,
          NewOrTracking: 'tracking',
        });

        return true;
      }
    }
    return false;
  }
  //#endregion
}
