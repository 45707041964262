






































import { Emit, Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { GetGridAccessPointInfoResponse, GridAccessPoint, IdentificationModel } from '@/models';
import { Mutation, State } from 'vuex-class'
import { Watch } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'
import { chatService } from '@/services'

@Component({
  name: 'identification'
})
export default class Identification extends Vue {
  //#region [Property]
  @Prop({ type: String, required: false, default: null })
  public ean!: string | null
  @Prop({ type: Object, required: false, default: null })
  public eanInfo!: GridAccessPoint | null
  //#endregion

  //#region [Data]
  @State('identification', { namespace: 'complaint/identification' })
  public identificationState!: IdentificationModel | null;

  public recaptchaToken: string | null = null
  public recaptchaKey: number = 0

  public loading: boolean = false
  public whereFoundEanModalOpen: boolean = false

  public eanCode: string | null = this.ean
  public gridAccessPointInfo: GridAccessPoint | null = this.eanInfo
  public isIdentified: boolean = false
  //#endregion

  //#region [Computed]
  public get isIdentifiedAndCanContinue(): boolean {
    this.$emit('canContinue', this.isIdentified && !!this.gridAccessPointInfo)
    return this.isIdentified && !!this.gridAccessPointInfo
  }
  //#endregion

  //#region [Mutation]
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void
  //#endregion

  //#region [Watch]
  @Watch('eanCode')
  @Emit('eanCode')
  public onEanCodeChange(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.identificationState?.eanCode && this.gridAccessPointInfo) {
      // User input is the same as last identified EAN
      this.isIdentified = true
      return value
    }
    this.isIdentified = false
    return value
  }

  @Watch('isIdentified')
  @Emit('isIdentified')
  public onIsIdentifiedChange(value: boolean): boolean | null {
    return value
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onEanCodeChange(this.ean);
  }

  public beforeDestroy() {
    this.$emit('canContinue', true);

  }

  public async getGridAccessPointInfo(): Promise<GetGridAccessPointInfoResponse> {
    const response = await this.$api.get<string, AxiosResponse<GetGridAccessPointInfoResponse>>(
        `api/assets/get-grid-access-point-info/${this.eanCode?.trim()}/complaint`,
        {
          headers: {
            'g-recaptcha-response': this.recaptchaToken
          },
          cache: {
            ignoreCache: false
          }
        }
    )
    return response.data
  }

  public async identify() {
    if (this.eanCode) {
      try {
        this.loading = true

        const getGridAccessPointInfoResponse = await this.getGridAccessPointInfo()

        this.gridAccessPointInfo = getGridAccessPointInfoResponse.gridAccessPointInfo
        this.$emit('eanInfo', this.gridAccessPointInfo)

        if (this.gridAccessPointInfo !== null) {
          if (getGridAccessPointInfoResponse.token) {
            sessionStorage.setItem('token', getGridAccessPointInfoResponse.token)
          }
        }

        this.loading = false
        this.isIdentified = true

        if (!this.isIdentifiedAndCanContinue) {
          // Refresh the captcha for next identification to work
          this.recaptchaKey += 1
        }
      } catch (err) {
        this.loading = false
        this.isIdentified = false
        this.$emit('eanInfo', null)
      }
    }
  }

  private openChat() {
    chatService.openChat()
  }

  //#endregion

  //#region [Emit]
  //#endregion
}
