var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validator",attrs:{"tag":"div"}},[(_vm.parentCategory === null || _vm.value.parentCategory.subCategories === null)?[_c('ValidationProvider',{attrs:{"rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('header',{staticClass:"lib__text-center"},[_c('h2',{staticClass:"lib__h2"},[_vm._v(_vm._s(_vm.$t('complaint.categorySelectorStep.sheConcerns')))])]),_c('div',{staticClass:"lib__field",class:[failed && 'lib__field--error', passed && (_vm.value.description !== null && _vm.value.description !== '') && 'lib__field--valid']},[_vm._l((_vm.categories),function(cat){return _c('TileRadio',{key:cat.key,attrs:{"vertical":"","group":"parentCategory","label":_vm.$t('complaint.category.' + cat.key),"required":"","item":cat.key},model:{value:(_vm.parentCategory),callback:function ($$v) {_vm.parentCategory=$$v},expression:"parentCategory"}})}),_c('error-message',{attrs:{"error":errors[0]}})],2)]}}],null,false,159783691)})]:[_c('header',{staticClass:"lib__text-center"},[_c('h2',{staticClass:"lib__h2"},[_vm._v(_vm._s(_vm.$t('complaint.categorySelectorStep.sheConcerns')))]),_c('button',{staticClass:"lib__btn lib__btn--rounded lib__btn--dark lib__btn--outline",attrs:{"type":"button"},on:{"click":function($event){_vm.parentCategory = null}}},[_c('span',[_vm._v(_vm._s(_vm.$t(("complaint.category." + _vm.parentCategory))))]),_c('i',{staticClass:"fas fa-times"})])]),_c('hr')],(_vm.value !== null && _vm.value.parentCategory !== null && _vm.value.parentCategory.subCategories !== null)?_c('div',[_c('ValidationProvider',{attrs:{"rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('header',{staticClass:"lib__text-center"},[_c('h2',{staticClass:"lib__h2"},[_vm._v(_vm._s(_vm.$t('complaint.categorySelectorStep.whatDoYouWish')))])]),_c('div',{staticClass:"lib__field",class:[failed && 'lib__field--error', passed && (_vm.value.description !== null && _vm.value.description !== '') && 'lib__field--valid']},[_vm._l((_vm.value.parentCategory.subCategories),function(cat){return _c('TileRadio',{key:cat.key,attrs:{"vertical":"","group":"category","label":_vm.$t('complaint.category.' + cat.key),"required":"","item":cat.key},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})}),_c('error-message',{attrs:{"error":errors[0]}})],2)]}}],null,false,2657181402)})],1):_vm._e(),_c('ModalDialog',{attrs:{"open":_vm.networkProblemsModalOpen},on:{"update:open":function($event){_vm.networkProblemsModalOpen=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('nav',{staticClass:"lib__btns-nav-list"},[_c('a',{staticClass:"lib__btn lib__btn--primary lib__btn--rounded",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.networkProblemsModalOpen = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t('complaint.categorySelectorStep.networkProblemsModal.btnText')))])])])]},proxy:true}])},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('complaint.categorySelectorStep.networkProblemsModal.content'))}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }