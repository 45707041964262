

































import { Component, Prop, Watch } from 'vue-property-decorator'
import Step from '../Step'
import { Mutation, State } from 'vuex-class'
import { helper } from '@/services'

@Component
export default class NewOrTrackingStep extends Step {
  //#region [Property]
  @Prop({ required: true, type: String }) public value!: string
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'complaint' })
  public readonly requestId!: string
  @State('newOrTracking', { namespace: 'complaint/newOrTracking' })
  public readonly newOrTrackingState!: string

  public newOrTracking: string | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'complaint/newOrTracking' })
  private store!: (index: string | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.newOrTracking = helper.clone(this.newOrTrackingState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('Complaint_StepNewOrTracking_Validated', {
        Segment: 'Complaint',
        WebRef: this.requestId,
      })
    }

    this.store(this.newOrTracking)
  }
  //#endregion

  //#region [Watch]
  @Watch('newOrTracking')
  public onNewOrTrackingChanged() {
    this.$emit('input', this.newOrTracking)
  }
  //#endregion
}
