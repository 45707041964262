




























































































import { Vue, Prop, Watch, Component } from 'vue-property-decorator'
import ZipCodeCheck from './ZipCodeCheck.vue'
import SubCitySelector from './SubCitySelector.vue'
import StreetSubCitySelector from './StreetSubCitySelector.vue'
import HouseNumberSelector from './HouseNumberSelector.vue'
import ConnectionPointArea from './ConnectionPointArea.vue'
import MapLocationPicker from './MapLocationPicker.vue'
import { AddressPoint, MapLocation, StreetSubCity, SubCity } from '@/models'
import { googleService } from '@/services'

@Component({
  components: {
    ZipCodeCheck,
    SubCitySelector,
    StreetSubCitySelector,
    HouseNumberSelector,
    ConnectionPointArea,
    MapLocationPicker,
  },
})
export default class AddressSelector extends Vue {
  //#region [Property]
  @Prop(Object) public readonly value!: AddressPoint | null
  @Prop({ type: Boolean, required: false, default: true }) public readonly numberRequired?: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly streetRequired?: boolean
  @Prop(Boolean) public readonly checkForGas?: boolean
  @Prop(Boolean) public readonly checkForElec?: boolean
  @Prop(Boolean) public readonly hideSuccessMessages?: boolean
  @Prop(Boolean) public readonly hideMap?: boolean
  @Prop(Boolean) public readonly showSynergridLink?: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly oresAddressOnly?: boolean
  //#endregion

  //#region [Data]
  public temporaryZipCode: string | null = null
  public subCity: SubCity | null = null
  public streetSubCity: StreetSubCity | null = null
  public houseNumber: string | null = null
  public houseBox: string | null = null
  public gasAllowed: boolean | null = null
  public elecAllowed: boolean | null = null
  public elecOrGasAllowed: boolean | null = null
  public addMapLocation: boolean = false
  public mapLocation: MapLocation | null = null

  public isZipCodeValid: boolean = false
  public isSubCityValid: boolean = false
  public isStreetSubCityValid: boolean = false
  public zipCodeNotInOres: boolean = false
  //#endregion

  //#region [Computed]
  get subCityId(): number | null {
    return this.subCity ? this.subCity.georesSubcityId : null
  }

  get streetSubCityId(): number | null {
    return this.streetSubCity ? this.streetSubCity.georesStreetSubcityId : null
  }

  get connectionPoint(): AddressPoint | null {
    if (this.subCity === null) {
      return null
    }

    const point = {
      subCity: this.subCity,
      streetSubCity: this.streetSubCity,
      houseNumber: this.houseNumber,
      houseBox: this.houseBox,
      isMapLocation: this.addMapLocation,
      mapLocation: this.mapLocation,
    }

    return point
  }

  get addressString(): string | null {
    return this.subCity ? `${this.subCity.zipCode} ${this.subCity.cityName}, Belgique` : null
  }

  get oresIsGrd(): boolean {
    if (this.oresAddressOnly && this.subCity) {
      const elecGrd =
        (this.subCity.operationalOffices.electricityOffice &&
          this.subCity.gridManagers.electricityManager &&
          this.subCity.gridManagers.electricityManager.isOres) ??
        false
      const gasGrd =
        (this.subCity.operationalOffices.gasOffice &&
          this.subCity.gridManagers.gasManager &&
          this.subCity.gridManagers.gasManager.isOres) ??
        false
      return elecGrd || gasGrd
    } else {
      return true
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('gasAllowed')
  public onGasAllowedChanged(allowed: boolean): void {
    if (allowed === null) {
      return
    }
    this.$emit('gasAllowed', allowed)
  }

  @Watch('elecAllowed')
  public onElecAllowedChanged(allowed: boolean): void {
    if (allowed === null) {
      return
    }
    this.$emit('elecAllowed', allowed)
  }

  @Watch('elecOrGasAllowed')
  public onElecOrGasAllowedChanged(allowed: boolean): void {
    if (allowed === null) {
      return
    }
    if (this.oresAddressOnly) {
      this.$emit('elecOrGasAllowed', allowed)
    }
  }

  @Watch('connectionPoint', { deep: true })
  public onConnectionPointChanged(val: AddressPoint | null, oldVal: AddressPoint | null): void {
    // Don't emit if no deeep value change
    if (val !== null && JSON.stringify(val) !== JSON.stringify(oldVal)) {
      this.$emit('input', val)
    }
  }

  @Watch('value')
  public onValueChanged(newVal: AddressPoint | null): void {
    if (!newVal) {
      // reset all fields
      this.temporaryZipCode = null
      this.subCity = null
      this.streetSubCity = null
      this.houseNumber = null
      this.houseBox = null
      this.addMapLocation = false
    } else {
      this.temporaryZipCode = newVal.subCity.zipCode
      this.subCity = newVal.subCity
      this.streetSubCity = newVal.streetSubCity
      this.houseNumber = newVal.houseNumber
      this.houseBox = newVal.houseBox
      this.addMapLocation = newVal.isMapLocation
      this.mapLocation = newVal.mapLocation
    }
  }

  @Watch('addMapLocation')
  public onAddMapLocationChanged(value: MapLocation): void {
    if (!value) {
      this.mapLocation = null
      this.$emit('mapLocation', false)
      this.clearHouseNumber()
    } else {
      this.$emit('mapLocation', true)
    }
  }
  //#endregion

  //#region [Method]
  public canAllowGasCallback(canAllowGas: boolean): void {
    this.gasAllowed = canAllowGas
  }

  public canAllowElecCallback(canAllowElec: boolean): void {
    this.elecAllowed = canAllowElec
  }

  public canAllowElecOrGasCallback(canAllowElecOrGas: boolean): void {
    this.elecOrGasAllowed = canAllowElecOrGas
  }

  public emitZipCodeError(): void {
    this.$emit('zipCodeError')
  }

  public clearHouseNumber(): void {
    this.houseNumber = null
    this.houseBox = null
  }

  public onZipCodeValidation(isValid: boolean): void {
    this.isZipCodeValid = isValid
  }

  public onSubCityValidation(isValid: boolean): void {
    this.isSubCityValid = isValid
  }

  public onStreetSubCityValidation(isValid: boolean): void {
    this.isStreetSubCityValid = isValid
  }

  public mounted() {
    googleService.includeMapScript(this.$el)
    this.onValueChanged(this.value)
  }
  //#endregion
}
