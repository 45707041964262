













import Identification from '@/components/inputs/complaint/identification/Identification.vue'
import IdentificationConfirmation from '@/components/inputs/complaint/identification/IdentificationConfirmation.vue'
import Component from 'vue-class-component'
import { GridAccessPoint } from '@/models'
import { Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
  name: 'identification-by-ean',
  components: {
    Identification,
    IdentificationConfirmation
  }
})
export default class IdentificationByEan extends Vue {
  //#region [Property]
  @Prop({ type: String, required: false, default: null })
  public eanCode!: string | null
  @Prop({ type: Object, required: false, default: null })
  public eanInfo!: GridAccessPoint | null
  //#endregion

  //#region [Data]
  public identified: boolean = false;
  //#endregion

  //#region [Method]
  @Emit('eanCode')
  public emitEanCode(ean: string | null) {
    return ean
  }

  @Emit('eanInfo')
  public emitEanInfo(eanInfo: GridAccessPoint | null) {
    return eanInfo
  }

  @Emit('canContinue')
  public emitCanContinue(value: boolean) {
    return value;
  }
  //#endregion
}
