





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { contactService } from '@/services'
import { AddressPoint, CategorySelectionStepModel, ConsumptionAddress, ContactInfo } from '@/models'
import ContactBaseForm from '@/components/inputs/contact/ContactBaseForm.vue'
import AddressForm from '@/components/inputs/contact/AddressForm.vue'
import ContactPersonForm from '@/components/inputs/contact/ContactPersonForm.vue'
import { State } from 'vuex-class'

@Component({
  name: 'contact-info-form',
  components: {
    ContactBaseForm,
    ContactPersonForm,
    AddressForm,
  },
})
export default class ContactForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: ContactInfo
  @Prop({ type: Object, required: false, default: null }) public addressPoint!: AddressPoint | null
  @Prop({ type: Object, required: false, default: null }) public consumptionAddress!: ConsumptionAddress | null
  @Prop({ type: Boolean, required: false, default: false }) public contactAddressRequired!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public hideOtherContact!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly showBirthdate!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly showNationalId!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly showCustomerRef!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly withContactType!: boolean
  //#endregion

  //#region [Data]
  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null
  //#endregion

  public get useOtherAddress(): number {
    return this.value.useOtherAddress ? 1 : 0
  }

  public set useOtherAddress(value: number) {
    this.value.useOtherAddress = !!value
  }

  public get addOtherContact(): boolean {
    return !!this.value.addOtherContact
  }

  public set addOtherContact(value: boolean) {
    this.value.addOtherContact = value
  }

  //#region [Computed]
  private get address() {
    let str = ''
    if (this.addressPoint) {
      if (this.addressPoint.streetSubCity) {
        if (this.addressPoint.houseNumber) {
          str += this.addressPoint.houseNumber
        }
        if (this.addressPoint.houseBox) {
          str += ' / ' + this.addressPoint.houseBox
        }
        str += ' ' + this.addressPoint.streetSubCity.name + '<br>'
      }
      str += this.addressPoint.subCity.zipCode
      str += ' ' + this.addressPoint.subCity.cityName
      return str
    }
    if (this.consumptionAddress) {
      str += this.consumptionAddress.houseNumber
      str += ' ' + this.consumptionAddress.streetName
      if (this.consumptionAddress.complement) {
        str += ' ' + this.consumptionAddress.complement
      }
      if (this.consumptionAddress.complementBox) {
        str += ' ' + this.consumptionAddress.complementBox
      }
      if (this.consumptionAddress.floorNumber) {
        str += ` (${this.$t(`common.identification.floor`).toString()}: ${this.consumptionAddress.floorNumber})`
      }
      str += '<br>' + this.consumptionAddress.zipCode
      str += ' ' + this.consumptionAddress.cityName
      return str
    }
    return ''
  }

  //#endregion

  //#region [Watch]
  @Watch('value.useOtherAddress')
  public onUseOtherAddressChanged(value: boolean) {
    if (value && this.value.otherAddress === null) {
      this.value.otherAddress = contactService.createContactAddress()
    } else if (!value && this.value.otherAddress !== null) {
      this.value.otherAddress = null
    }
  }

  @Watch('value.addOtherContact')
  public onAddOtherContactChanged(value: boolean) {
    if (value && this.value.otherContact === null) {
      this.value.otherContact = contactService.createContactPerson()
    } else if (!value && this.value.otherContact !== null) {
      this.value.otherContact = null
    }
  }

  //#endregion
}
